import * as React from "react";

const Header = () => {
  return (
    <h1
      style={{
        fontSize: 70,
        color: "#000000",
        margin: 15,
        fontWeight: 600,
        lineHeight: 1.12,
        fontStyle: "normal",
        letterSpacing: "0em",
        textTransform: "none",
      }}
    >
      Software engineer and artist, based in Seattle.
    </h1>
  );
};

export default Header;
