import * as React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMediumM,
  faLinkedinIn,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

const Link = ({ icon, href }) => {
  const [opacity, setOpacity] = useState(1);
  return (
    <a
      href={href}
      onMouseEnter={() => {
        setOpacity(0.5);
      }}
      onMouseLeave={() => {
        setOpacity(1);
      }}
      style={{
        opacity,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 60,
        width: 60,
        background: "#000000",
        marginRight: 15,
      }}
    >
      <FontAwesomeIcon icon={icon} color="#E4FF25" size="2x" />
    </a>
  );
};

const Links = () => {
  return (
    <div style={{ display: "flex", alignItems: "stretch", marginLeft: 15 }}>
      <Link icon={faLinkedinIn} href="https://www.linkedin.com/in/nedwin/" />
      <Link icon={faMediumM} href="https://medium.com/@edwinlwhitman/" />
      <Link icon={faTwitter} href="https://twitter.com/othernedwin" />
    </div>
  );
};

export default Links;
