import * as React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Navigation";
import Landing from "../screens/Landing";

import "./index.css";
// imports bootstrap css
import "bootstrap/dist/css/bootstrap.min.css";
// The following import prevents a Font Awesome icon server-side rendering bug,
// where the icons flash from a very large icon down to a properly sized one:
import "@fortawesome/fontawesome-svg-core/styles.css";
// Prevent fontawesome from adding its CSS since we did it manually above:
import { config } from "@fortawesome/fontawesome-svg-core";
config.autoAddCss = false; /* eslint-disable import/first */

const containerStyle = {
  display: "flex",
  flexFlow: "column",
  height: "100vh",
  backgroundColor: "#E4FF25",
};

const navigationStyle = {
  flex: "0 1 auto",
};

const contentStyle = {
  flex: "1 1 auto",
};

const App = () => {
  return (
    <div className="application" style={containerStyle}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Edwin Whitman</title>
        <link rel="canonical" href="https://nedw.in/" />
        <link rel="icon" type="image/x-icon" href="favicon.ico" sizes="16x16" />
        <meta
          name="description"
          content="Edwin Whitman, also known as Ned Whitman."
        />
      </Helmet>
      <div style={navigationStyle}>
        <Navigation />
      </div>
      <div style={contentStyle}>
        <Landing />
      </div>
    </div>
  );
};

export default App;
