import * as React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

const Navigation = () => {
  return (
    <Navbar>
      <Nav variant="pills" activeKey={null}>
        <Nav.Item style={{ marginTop: 15, marginLeft: 45, marginBottom: 15 }}>
          <Navbar.Brand
            style={{
              fontSize: "2rem",
              fontWeight: 700,
              fontStyle: "normal",
              letterSpacing: "0em",
              lineHeight: "1.4em",
            }}
          >
            Edwin Whitman
          </Navbar.Brand>
        </Nav.Item>
      </Nav>
    </Navbar>
  );
};

export default Navigation;
