import * as React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Portrait from "../components/Portrait";
import Header from "../components/Header";
import Links from "../components/Links";
import { Helmet } from "react-helmet";

// var duotones = [
//   ["593a59", "eba52c"],
//   ["eb453d", "a9fdee"],
//   ["36217c", "f91f36"],
//   ["573c59", "ef6643"],
//   ["e873aa", "f8f23b"],
//   ["2c2e83", "90f18c"],
//   ["1b7953", "ffbdcc"],
// ];

const Landing = () => {
  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Helmet>
        <meta property="og:title" content="Edwin Whitman" />
        <meta
          property="og:description"
          content="Edwin Whitman, also known as Ned Whitman."
        />
        <meta property="og:url" content="nedw.in" />
        <meta property="og:image" content="preview.png" />
        <meta property="og:site_name" content="Edwin Whitman" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="profile" />
      </Helmet>

      <Portrait duotone={["000000", "E4FF25"]} />
      <Container fluid style={{ zIndex: 1 }}>
        <Row>
          <Col xs={0} sm={1} md={1} lg={1} xl={1} xxl={1} />
          <Col xs={10} sm={9} md={8} lg={6} xl={6} xxl={6}>
            <Header />
            <Links />
          </Col>
          <Col xs={2} sm={2} md={3} lg={5} xl={5} xxl={5} />
        </Row>
      </Container>
    </div>
  );
};

export default Landing;
